export enum FeatureFlagEnum {
  isMyFirstFeatureEnabled = "isMyFirstFeatureEnabled",
  addRFPRecipientsThroughSupplierSearch = "addRFPRecipientsThroughSupplierSearch",
  azureFlag = "isAzureFeatureEnabled",
  dataroom = "dataroom",
  mySuppliers = "mySuppliers",
  groupsEnabled = "groupsEnabled",
  evaluationSettings = "evaluationSettings",
  notificationsEnabled = "notificationsEnabled",
  questionWeight = "questionWeight",
  questionTypes = "questionTypes",
  posting = "posting",
  comments = "comments",
  registerWithZone = "registerWithZone",
  postAttachments = "postAttachments",
  postVideos = "postVideos",
  exportRfpResponse = "exportRfpResponse",
  groupFiles = "groupFiles",
  manageGroupFilesApproval = "manageGroupFilesApproval",
  duplicateRfp = "duplicateRfp",
  passport = "passport",
  supplierContracts = "supplierContracts",
  supplierResponses = "supplierResponses",
  numericAutoScoring = "numericAutoScoring",
  supplierProducts = "supplierProducts",
  dataManagement = "dataManagement",
  matrixQuestion = "matrixQuestion",
  agGridEnterprise = "agGridEnterprise",
  csrd = "csrd",
  exportImportRfxJson = "exportImportRfxJson",
  createNewCompany = "createNewCompany",
  rfxEditorGridLayout = "rfxEditorGridLayout",
  valueChainDashboardChart = "valueChainDashboardChart",
}
