export enum Direction {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TokenType {
  Refresh = "Refresh",
  PasswordReset = "PasswordReset",
  EmailVerify = "EmailVerify",
}

export enum CommentStatus {
  Pending = "Pending",
  Sent = "Sent",
}

export enum CSRDDocumentStatus {
  NotRelevant = "Not Relevant",
  NotStarted = "Not Started",
  Draft = "Draft",
  InReview = "InReview",
  Completed = "Completed",
}

export enum RfpResponseStatus {
  Draft = "Draft",
  InReview = "InReview",
  Submitted = "Submitted",
}

export enum DynamicRfpResponseStatus {
  /** Applies to unanswered invitations */
  New = "New",
  Draft = "Draft",
  /** Read from invitation status */
  Declined = "Declined",
  InReview = "InReview",
  Submitted = "Submitted",
  /** After due date and it's not submitted nor declined */
  NotResponded = "NotResponded",
}

export enum PassportStatus {
  None = "None",
  Draft = "Draft",
  InReview = "InReview",
  Submitted = "Submitted",
  Completed = "Completed",
  CloseToExpire = "CloseToExpire",
  Expired = "Expired",
}

// Union of DynamicRfpResponseStatus and PassportStatus
export enum RfpResponseDtoStatus {
  New = "New",
  Draft = "Draft",
  Declined = "Declined",
  InReview = "InReview",
  Submitted = "Submitted",
  NotResponded = "NotResponded",
  None = "None",
  Completed = "Completed",
  CloseToExpire = "CloseToExpire",
  Expired = "Expired",
}

export enum RfpResponseEvaluationStatus {
  ReadyForEvaluation = "ReadyForEvaluation",
  InEvaluation = "InEvaluation",
  Evaluated = "Evaluated",
}

export enum RfpResponseAnalysisStatus {
  ReadyForAnalysis = "ReadyForAnalysis",
  InAnalysis = "InAnalysis",
  Analysed = "Analysed",
}

export enum NotificationType {
  InvitedForRFP = "InvitedForRFP",
  InvitedForDDQ = "InvitedForDDQ",
  InvitedForRFI = "InvitedForRFI",
  InvitedForQTP = "InvitedForQTP",
  /** todo - is this needed? */
  InvitedForPassport = "InvitedForPassport",
  RFPResponseReceived = "RFPResponseReceived",
  DDQResponseReceived = "DDQResponseReceived",
  RFIResponseReceived = "RFIResponseReceived",
  QTPResponseReceived = "QTPResponseReceived",
  GroupInvitationReceived = "GroupInvitationReceived",
  GroupJoinRequestReceived = "GroupJoinRequestReceived",
  GroupInvitationApproved = "GroupInvitationApproved",
  GroupDeleted = "GroupDeleted",
  PostApproveRequestReceived = "PostApproveRequestReceived",
  PostApproved = "PostApproved",
  DocumentMessageReceived = "DocumentMessageReceived",
  DocumentReplyReceived = "DocumentReplyReceived",
  /** https://dev.azure.com/PulseMarketplace/pulse-marketplace/_workitems/edit/5802 - map to a passport's id */
  OwnPassportReportReady = "OwnPassportReportReady",
  /** TBD - not on wireframes */
  // PassportResponseSent = "PassportResponseSent",
  /** map to a passport's id */
  RenewPassport = "RenewPassport",
  /** map to passport's id */
  InvitedContactPassportReportReady = "InvitedContactPassportReportReady",
  ProcurementFeedbackReceived = "ProcurementFeedbackReceived",
}
