import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { QueryClient, useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import queryKeys from "reactQuery/queryKeys";

import { RfpInvitationDto } from "@pulsemarket/api-client";
import { RFPType, ROUTES } from "@pulsemarket/constants";
import ExistingUserInvitatationModal from "components/Onboarding/ExistingUserInvitationPopup";
import MultiCompanyInvitationSelectorModal, {
  MultiCompanyInvitationType,
} from "components/Passports/Modals/MultiCompanyInvitationSelectorModal";
import { unclosableDialogProps } from "components/ui/Modal";
import SimpleModalBody from "components/ui/Modal/SimpleModalBody/SimpleModalBody";
import ModalContext, { uncloseableDialogSettings } from "contexts/ModalContext";
import { ModalContextType } from "contexts/ModalContext/ModalContext";
import { History } from "history";
import { useUserCompanies } from "hooks/queries/useUserCompanies";
import useUserData from "hooks/queries/useUserData";
import {
  LocalStorageKey,
  removeInvitationParamsFromLocalStorage,
  setCookieArray,
  TriggeredPopupEnum,
} from "hooks/utils";
import {
  connectToGroup,
  connectToRFP,
  getCompany,
  getGroupByInvitationId,
  getRfpByInvitationId,
  getRfpInvitation,
  getUserCompanyRole,
  switchCompany,
} from "shared/client";
import { ACTING_COMPANY_ID } from "shared/constants/localstorage";
import {
  CompanyDetailsDto,
  GroupDetailsDto,
  StandardRFPType,
  UserCompanyRole,
  UserDto,
} from "shared/model";

export const defaultLoadingMessage = "Getting everything ready...";

export const useCheckInvitations = () => {
  const [loadingMessage, setLoadingMessage] = useState<string | null>(null);
  const client = useQueryClient();

  const { data: userData, isLoading: userDataLoading } = useUserData();

  const { openModal, closeModal } = useContext(ModalContext);
  const { data: userCompanies, isLoading: companiesLoading } =
    useUserCompanies();

  const history = useHistory();

  useEffect(() => {
    (async () => {
      if (companiesLoading || userDataLoading) {
        setLoadingMessage(defaultLoadingMessage);
        return;
      }

      if (!userData?.id) {
        setLoadingMessage(null);
        return;
      }

      const redirectParam = localStorage.getItem(LocalStorageKey.REDIRECT);
      const redirectToMyESGPassports = redirectParam === ROUTES.MY_ESG_PASSPORT;

      // Only one of these will be true since an invitation can only be for one thing
      const isCompanyMemberInvitation = hasCompanyMemberInvitationCookieKey();
      const isGroupInvitation = hasGroupInvitationCookieKey();
      const isRfpInvitation =
        hasRfpInvitationCookieKey() || redirectToMyESGPassports;

      // User invited by a company to join as member
      if (isCompanyMemberInvitation) {
        await handleCompanyMemberInvitation({
          user: userData,
          client,
          history,
          openModal,
          closeModal,
          setLoadingMessage,
        });
      } else if (isRfpInvitation) {
        await handleRfpInvitation({
          userCompanies,
          user: userData,
          client,
          history,
          openModal,
          closeModal,
          setLoadingMessage,
          isEsgRedirectLink: redirectToMyESGPassports,
        });
      } else if (isGroupInvitation) {
        await handleGroupInvitation({
          userCompanies,
          user: userData,
          client,
          history,
          openModal,
          closeModal,
          setLoadingMessage,
        });
      }
      setLoadingMessage(null);
    })();
  }, [
    client,
    companiesLoading,
    history,
    userCompanies,
    userData,
    userDataLoading,
    openModal,
    closeModal,
  ]);

  return { loadingMessage };
};

type InvitationHandlerProps = {
  user: UserDto;
  userCompanies?: CompanyDetailsDto[];
  client: QueryClient;
  history: History<unknown>;
  setLoadingMessage: Dispatch<SetStateAction<string | null>>;
  openModal: ModalContextType["openModal"];
  closeModal: ModalContextType["closeModal"];
};

async function handleGroupInvitation({
  user,
  userCompanies,
  client,
  history,
  openModal,
  closeModal,
  setLoadingMessage,
}: InvitationHandlerProps) {
  if (user) {
    let group: GroupDetailsDto | null = null;
    let inviterCompany: CompanyDetailsDto | null = null;

    const inviterCompanyId = localStorage.getItem(
      LocalStorageKey.INVITER_COMPANY_ID
    );
    const invitationId = localStorage.getItem(
      LocalStorageKey.GROUP_INVITATION_TOKEN
    );
    setLoadingMessage(defaultLoadingMessage);
    try {
      inviterCompany = await getCompany(inviterCompanyId!);
      group = await getGroupByInvitationId(invitationId!);
    } catch (err) {
      // If group or inviter company not found contiune with clearing local storage
      removeInvitationParamsFromLocalStorage();
      return;
    }
    setLoadingMessage(null);

    // Open multi company modal here
    if (userCompanies?.length && userCompanies.length > 1) {
      openModal(
        <MultiCompanyInvitationSelectorModal
          onConfirm={async (companyId) => {
            setLoadingMessage(defaultLoadingMessage);

            // Confirm success in simpeConfirmationModal
            try {
              await connectToGroup(invitationId!, companyId);
              localStorage.setItem(ACTING_COMPANY_ID, companyId);
              await switchCompany({ activeCompanyId: companyId });
              await client.invalidateQueries(queryKeys.user);
              await client.invalidateQueries(queryKeys.ownCompany);
            } catch (err) {
              openModal(
                <SimpleModalBody
                  title="Invalid invitation"
                  buttons={[
                    {
                      variant: "normal",
                      children: "Ok, got it",
                      color: "orange",
                      onClick: () => {
                        history.push("/");
                        closeModal();
                      },
                    },
                  ]}
                >
                  The invitation token is not valid.
                </SimpleModalBody>
              );
            }
            await client.invalidateQueries(queryKeys.group(group!.id));

            setLoadingMessage(null);
          }}
          companies={userCompanies}
          type={MultiCompanyInvitationType.Group}
          inviterCompanyName={inviterCompany.name}
          groupName={group.name}
        />,
        { props: unclosableDialogProps }
      );
    } else {
      setLoadingMessage(defaultLoadingMessage);
      await connectToGroup(invitationId!, user.companyId);
      await client.invalidateQueries(queryKeys.group(group!.id));
      setLoadingMessage(null);
    }
    removeInvitationParamsFromLocalStorage();
  }
}

async function handleRfpInvitation({
  user,
  userCompanies,
  client,
  history,
  openModal,
  closeModal,
  setLoadingMessage,
  isEsgRedirectLink,
}: InvitationHandlerProps & { isEsgRedirectLink?: boolean }) {
  const rfpInvitationToken = localStorage.getItem(
    LocalStorageKey.RFP_INVITATION_TOKEN
  );
  let rfp: {
    type: RFPType;
    id: string;
    name: string;
  } | null = null;

  let rfpInvitation: RfpInvitationDto | null = null;
  let inviterCompany = null;
  setLoadingMessage(defaultLoadingMessage);

  try {
    rfp = await getRfpByInvitationId(rfpInvitationToken!);
    rfpInvitation = await getRfpInvitation(rfpInvitationToken!);
    inviterCompany = await getCompany(rfpInvitation.companyId);
  } catch (err) {
    // If RFP or inviter company not found contiune with clearing local storage
    removeInvitationParamsFromLocalStorage();
    setLoadingMessage(null);
    return;
  }
  if (
    rfpInvitation.invitedCompanyId &&
    userCompanies?.find((c) => c.id === rfpInvitation?.invitedCompanyId)
  ) {
    //swtich to the invited company if the user has access to it
    localStorage.setItem(ACTING_COMPANY_ID, rfpInvitation.invitedCompanyId);

    await switchCompany({ activeCompanyId: rfpInvitation.invitedCompanyId });

    await client.invalidateQueries();
    await client.refetchQueries(queryKeys.user);
    await client.refetchQueries(queryKeys.ownCompany);
  }

  if (
    userCompanies?.length &&
    userCompanies.length > 1 &&
    !!rfp &&
    !rfpInvitation.invitedCompanyId
  ) {
    openModal(
      <MultiCompanyInvitationSelectorModal
        companies={userCompanies}
        type={
          rfp.type === RFPType.Passport
            ? MultiCompanyInvitationType.ESG
            : MultiCompanyInvitationType.RFX
        }
        inviterCompanyName={inviterCompany.name}
        rfxType={
          [RFPType.Passport, RFPType.ESRS, RFPType.Regulation].includes(
            rfp.type
          )
            ? undefined
            : StandardRFPType[rfp.type as keyof typeof StandardRFPType]
        }
        onConfirm={async (companyId) => {
          setLoadingMessage(defaultLoadingMessage);
          if (rfp!.type === RFPType.Passport) {
            setCookieArray({
              key: LocalStorageKey.OPEN_POPUP,
              type: "add",
              value: TriggeredPopupEnum.GetPassport,
            });
          }
          // Confirm success in simpeConfirmationModal
          try {
            await connectToRFP(rfpInvitationToken!, companyId);
            localStorage.setItem(ACTING_COMPANY_ID, companyId);

            await switchCompany({ activeCompanyId: companyId });

            await client.invalidateQueries();
            await client.refetchQueries(queryKeys.user);
            await client.refetchQueries(queryKeys.ownCompany);
          } catch (err) {
            openModal(
              <SimpleModalBody
                title="Invalid invitation"
                buttons={[
                  {
                    variant: "normal",
                    children: "Ok, got it",
                    color: "orange",
                    onClick: () => {
                      history.push("/");
                      closeModal();
                    },
                  },
                ]}
              >
                The invitation token is not valid.
              </SimpleModalBody>
            );
          }

          setLoadingMessage(null);
        }}
      />,
      { props: unclosableDialogProps }
    );
  } else {
    setLoadingMessage(defaultLoadingMessage);
    if (!rfpInvitation.invitedCompanyId) {
      await connectToRFP(rfpInvitationToken!, user.companyId);
    }

    await client.invalidateQueries(queryKeys.rfp(rfp?.id));
    localStorage.removeItem(LocalStorageKey.RFP_INVITATION_TOKEN);

    if (rfp.type === RFPType.Passport) {
      setCookieArray({
        key: LocalStorageKey.OPEN_POPUP,
        type: "add",
        value: TriggeredPopupEnum.GetPassport,
      });
    }

    setLoadingMessage(null);
  }

  if (rfp?.type === RFPType.Passport || isEsgRedirectLink) {
    setCookieArray({
      key: LocalStorageKey.OPEN_POPUP,
      type: "add",
      value: TriggeredPopupEnum.GetPassport,
    });
  }

  setLoadingMessage(null);
}

async function handleCompanyMemberInvitation({
  user,
  openModal,
  setLoadingMessage,
}: InvitationHandlerProps) {
  setLoadingMessage(defaultLoadingMessage);
  console.log("User was invited by a company to join as member");

  const invitationId = localStorage.getItem(
    LocalStorageKey.COMPANY_MEMBER_INVITATION_TOKEN
  );
  const inviterCompanyId = localStorage.getItem(
    LocalStorageKey.INVITER_COMPANY_ID
  );

  const inviterCompany = await getCompany(inviterCompanyId!);
  setLoadingMessage(null);

  let existingRole: null | UserCompanyRole = null;
  setLoadingMessage(defaultLoadingMessage);

  try {
    existingRole = await getUserCompanyRole(inviterCompany.id, user.id);
  } catch (err) {
    // If role combination not found, continue with company member invitation mapping
  }
  setLoadingMessage(null);

  if (existingRole) {
    removeInvitationParamsFromLocalStorage();
  } else {
    openModal(
      <ExistingUserInvitatationModal
        companyName={inviterCompany.name}
        setLoadingMessage={setLoadingMessage}
        inviterCompanyId={inviterCompanyId!}
        token={invitationId!}
      />,
      uncloseableDialogSettings("md")
    );
  }
}

function hasCompanyMemberInvitationCookieKey() {
  return Boolean(
    localStorage.getItem(LocalStorageKey.COMPANY_MEMBER_INVITATION_TOKEN) &&
      localStorage.getItem(LocalStorageKey.INVITER_COMPANY_ID)
  );
}

function hasGroupInvitationCookieKey() {
  return Boolean(
    localStorage.getItem(LocalStorageKey.GROUP_INVITATION_TOKEN) &&
      localStorage.getItem(LocalStorageKey.INVITER_COMPANY_ID)
  );
}

function hasRfpInvitationCookieKey() {
  return Boolean(localStorage.getItem(LocalStorageKey.RFP_INVITATION_TOKEN));
}
