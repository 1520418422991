import { InvitationType } from "shared/model";

export enum SearchParamEnum {
  INVITER_COMPANY = "inviterCompany",
  COMPANY_MEMBER_INVITATION_TOKEN = "companyMemberInvitationToken",
  RFP_INVITATION_TOKEN = "invitationId",
  GROUP_INVITATION_TOKEN = "groupMemberInvitationToken",
  REDIRECT_PAGE_PARAM = "redirect_to",
  OPEN_POPUP = "openPopup",
}

export enum LocalStorageKey {
  COMPANY_MEMBER_INVITATION_TOKEN = "companyMemberInvitationToken",
  GROUP_INVITATION_TOKEN = "groupMemberInvitationToken",
  RFP_INVITATION_TOKEN = "rfpInvitationToken",
  INVITER_COMPANY_ID = "inviterCompanyId",
  OPEN_POPUP = "openPopup",
  REDIRECT = "redirect_to",
}

export enum TriggeredPopupEnum {
  GetPassport = "GetPassport",
  RFXRecipients = "RFXRecipients",
  RFXFeedback = "RFXFeedback",
}

export function getSearchParam(name: SearchParamEnum) {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
}

export function getInvitationFromLocalStorage() {
  const rfpInvitationId = localStorage.getItem(
    LocalStorageKey.RFP_INVITATION_TOKEN
  );
  const groupInvitationId = localStorage.getItem(
    LocalStorageKey.GROUP_INVITATION_TOKEN
  );

  const companyMemberInvitationId = localStorage.getItem(
    LocalStorageKey.COMPANY_MEMBER_INVITATION_TOKEN
  );

  const inviterCompanyId = localStorage.getItem(
    LocalStorageKey.INVITER_COMPANY_ID
  );

  if (rfpInvitationId) {
    return {
      invitationId: rfpInvitationId,
      invitationType: InvitationType.rfp,
    };
  } else if (groupInvitationId && inviterCompanyId) {
    return {
      invitationId: groupInvitationId,
      invitationType: InvitationType.group,
      inviterCompanyId,
    };
  } else if (companyMemberInvitationId && inviterCompanyId) {
    return {
      invitationId: companyMemberInvitationId,
      invitationType: InvitationType.companyMember,
      inviterCompanyId,
    };

    //TODO AUTH: Handle data room invitation
  }
}

export function removeInvitationParamsFromLocalStorage() {
  localStorage.removeItem(LocalStorageKey.COMPANY_MEMBER_INVITATION_TOKEN);
  localStorage.removeItem(LocalStorageKey.GROUP_INVITATION_TOKEN);
  localStorage.removeItem(LocalStorageKey.RFP_INVITATION_TOKEN);
  localStorage.removeItem(LocalStorageKey.INVITER_COMPANY_ID);
}

export function removeSearchParam(name: SearchParamEnum) {
  const params = new URLSearchParams(window.location.search);
  params.delete(name);
  const search = params.toString();
  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}${search ? "?" + search : ""}`
  );
}

export function setCookieArray({
  key,
  type,
  value,
}: {
  key: LocalStorageKey;
  value: TriggeredPopupEnum;
  type: "add" | "remove";
}) {
  const cookie = localStorage.getItem(key);
  const cookieArray = cookie ? cookie.split(",") : [];
  if (type === "add") {
    //add unique valies only
    if (!cookieArray.includes(value)) {
      cookieArray.push(value);
    }
  } else {
    cookieArray.splice(cookieArray.indexOf(value), 1);
  }
  localStorage.setItem(key, cookieArray.join(","));
}

export function getCookieArray({
  key,
  value,
}: {
  key: LocalStorageKey;
  value: TriggeredPopupEnum;
}) {
  const cookie = localStorage.getItem(key);
  const cookieArray = cookie ? cookie.split(",") : [];
  return cookieArray.includes(value);
}
