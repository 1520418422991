import { useHistory } from "react-router-dom";

import { ROUTES } from "@pulsemarket/constants";
import SvgIcon from "components/ui/SvgIcon";
import { useGetRegulations } from "hooks/queries/regulationHooks";
import {
  MenuItemGroup,
  SideMenuItemProps,
  SubMenuItemProps,
} from "layout/SideMenu/SideMenu";

import styles from "./SideMenuStyles";

function useGetRegulationMenuItem({
  isOpen,
}: {
  isOpen: boolean;
}): SideMenuItemProps {
  const history = useHistory();
  const { data: regulations } = useGetRegulations();

  const regulationMenuItems: SubMenuItemProps["items"] = (
    regulations || []
  )?.map((regulation) => ({
    label: regulation.name,
    onClick: () => {
      history.push(`${ROUTES.REGULATIONS({ regulation }).Dashboard}`);
    },
  }));

  return {
    group: MenuItemGroup.Resources,
    label: "Regulations",
    showLabel: isOpen,
    icon: (
      <SvgIcon
        src="/icons/certifications_icon.svg"
        className={styles.itemIconClass}
      />
    ),
    onClick: () => {},
    subMenu: {
      title: "Regulations and Frameworks",
      items: regulationMenuItems,
    },
  };
}

export default useGetRegulationMenuItem;
