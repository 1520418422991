import { useQuery } from "react-query";
import queryKeys from "reactQuery/queryKeys";
import { longCachedQueryOptions } from "reactQuery/util";

import { getRegulations } from "shared/client";

export function useGetRegulations() {
  return useQuery(queryKeys.regulations, getRegulations, {
    ...longCachedQueryOptions,
  });
}
